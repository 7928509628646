import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarFill,faGraduationCap } from '@fortawesome/pro-solid-svg-icons'
import { faCheckCircle,faPlay,faCheck,faVideo,faDownload,faKey,faPhoneLaptop,faBooks,faFileCertificate,faChalkboardTeacher,faUserClock,faUserHeadset,faTasks,faStar,faUser,faCommentAltLines } from '@fortawesome/pro-light-svg-icons'

class FontIconComponent extends Component {
    components = {
        faStarFill: faStarFill,
        faCheckCircle: faCheckCircle,
        faPlay: faPlay,
        faCheck: faCheck,
        faVideo: faVideo,
        faDownload: faDownload,
        faKey: faKey,
        faPhoneLaptop: faPhoneLaptop,
        faBooks: faBooks,
        faFileCertificate: faFileCertificate,
        faChalkboardTeacher: faChalkboardTeacher,
        faUserClock: faUserClock,
        faUserHeadset: faUserHeadset,
        faTasks: faTasks,
        faStar: faStar,
        faUser: faUser,
        faCommentAltLines: faCommentAltLines,
        faGraduationCap:faGraduationCap
    };
    render() {
       const TagName = this.components[this.props.tag || 'foo'];
       if (this.props.tag !== "faStarFill") {
          return <FontAwesomeIcon style={{marginRight:"5px"}} icon={TagName} className="iconKeyHighlights" />
       }
       else {
         return <FontAwesomeIcon style={{marginRight:"5px"}} icon={TagName} className="iconKeyHighlights faStarRating" />
       }

    }
}
export default FontIconComponent;
